import { Component, HostBinding } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  getLanguage,
  getMainNavigation,
  getRenderingData,
  getShowMinimalNavigation,
  JssStateFatalError,
  JssStatePlaceholderLoaded,
} from '@innogy/jss-integration';
import { catchError, map } from 'rxjs/operators';
import { combineLatest, EMPTY } from 'rxjs';

@Component({
  selector: 'ep-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.ew.scss'],
})
export class HeaderComponent {
  @HostBinding('class')
  class = 'd-block sticky-top';

  public isCanvasMenuOpen = false;

  public readonly language$ = this.store$.select(getLanguage);
  public readonly hasMinimalNavigation$ = this.store$.select(
    getShowMinimalNavigation
  );
  public readonly mainNavigationInfo$ = this.store$.pipe(getMainNavigation);

  // Preferably the creation of vm should be moved to a selector, only possible when getMainNavigation is a selector instead of pipeable operator
  public vm$ = combineLatest([
    this.language$,
    this.hasMinimalNavigation$,
    this.mainNavigationInfo$,
  ]).pipe(
    map(([language, minimalNavigation, mainNavigationInfo]) => {
      return {
        language,
        minimalNavigation,
        isMainNavigationAvailable:
          mainNavigationInfo?.isNavigationAvailable && !minimalNavigation,
        mainNavigationItems: mainNavigationInfo.navItems,
        isLoaded: !!(mainNavigationInfo && language),
      };
    })
  );

  rendering$ = this.store$.pipe(
    getRenderingData,
    catchError((error: unknown) => {
      this.store$.dispatch(
        new JssStateFatalError({ route: 'ServerError', error: error as Error })
      );
      return EMPTY;
    })
  );

  triggerLoaded(placeholderName?: string) {
    this.store$.dispatch(new JssStatePlaceholderLoaded(placeholderName));
  }

  constructor(private readonly store$: Store<any>) {}

  openCanvasMenu() {
    this.isCanvasMenuOpen = true;
  }

  closeCanvasMenu() {
    this.isCanvasMenuOpen = false;
  }
}
