import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/jss-models';
import type { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'ep-product-grid-item',
  templateUrl: './product-grid-item.component.html',
  styleUrls: ['./product-grid-item.component.ew.scss'],
})
export class ProductGridItemComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;

  fields?: ComponentFields;

  ngOnInit(): void {
    if (this.rendering?.fields) {
      this.fields = this.rendering.fields;
    }
  }
}
