import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt';
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons/faPaperclip';
import { StoreModule } from '@ngrx/store';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { EffectsModule } from '@ngrx/effects';
import { UtilsModule } from '@innogy/utils-deprecated';
import { CoreExperienceEditorModule } from '@innogy/core/experience-editor';

import { imageUploadSelectorKey } from './+state/image-upload.selectors';
import { imageUploadReducer } from './+state/image-upload.reducer';
import { ImageUploadEffects } from './+state/image-upload.effects';
import { DropZoneComponent } from './drop-zone/drop-zone.component';
import { ImageListComponent } from './image-list/image-list.component';
import { ImageUploadPreviewComponent } from './image-upload-preview/image-upload-preview.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    JssModule,
    UtilsModule,
    StoreModule.forFeature(imageUploadSelectorKey, imageUploadReducer),
    EffectsModule.forFeature([ImageUploadEffects]),
    CoreExperienceEditorModule,
  ],
  declarations: [
    ImageUploadComponent,
    DropZoneComponent,
    ImageListComponent,
    ImageUploadPreviewComponent,
  ],
  exports: [ImageUploadComponent, ImageListComponent],
})
export class EplusImageUploadModule {
  constructor(library: FaIconLibrary) {
    createLibrary(library);
  }
}

export const createLibrary = (library: FaIconLibrary) => {
  library.addIcons(faTrashAlt, faPaperclip);
};
