import { isEmailAddress, validateSequential } from '@innogy/utils-deprecated';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  onNgrxForms,
  updateGroup,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { required, requiredTrue } from 'ngrx-forms/validation';

import { clearMoreInformationForm } from './send-more-information.actions';

export const EMAIL_SIGNUP_FORM_ID = 'sendMoreInformation';
export const MAILING_LIST_FORM_ID = `${EMAIL_SIGNUP_FORM_ID}.mailingList`;
export const MANDATORY_CHECKBOX_ID = `${EMAIL_SIGNUP_FORM_ID}.mandatoryCheckbox`;

interface FormValues {
  mandatoryCheckbox: boolean;
  mailingList: string;
  email: string;
}

export type SendMoreInformationFormState = FormGroupState<FormValues>;

export const initialFormState: FormValues = {
  mandatoryCheckbox: false,
  mailingList: '',
  email: '',
};

export const initialState = createFormGroupState<FormValues>(
  EMAIL_SIGNUP_FORM_ID,
  initialFormState
);

export const validateAndUpdateForms = (state: SendMoreInformationFormState) => {
  return updateGroup<FormValues>({
    mandatoryCheckbox: validateSequential(requiredTrue),
    email: validateSequential(required, isEmailAddress),
  })(state);
};

const _reducer = createReducer(
  initialState,
  onNgrxForms(),
  on(clearMoreInformationForm, () => initialState)
);

const wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state: SendMoreInformationFormState) => state,
  (_, state) => validateAndUpdateForms(state)
);

export function sendMoreInformationReducer(
  state: SendMoreInformationFormState = initialState,
  action: Action
) {
  return wrappedReducer(state, action);
}
