import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { AddressCoreModule } from '@essent/address';
import { EplusEnergiewonenTwoSolarModule } from '@innogy/eplus-partners/energiewonen/twosolar';
import {
  DefaultComponentModule,
  DefaultFormComponentModule,
} from '@innogy/core/modules/components';
import { apiPublicBasePath } from '@innogy/core-config-angular';
import { EplusUiOnlyModule } from '@innogy/eplus-partners/ui-only';
import { EplusSolarPanelsModule } from '@innogy/eplus/solarpanels';

import { BasicIntakeFormComponent } from './basic-intake-form/basic-intake-form.component';
import { BasicIntakeFormEffects } from './+state/basic-intake-form/basic-intake-form.effects';
import { InputFormStepComponent } from './intake/steps/input/input-form-step.component';
import { IntakeComponent } from './intake/intake.component';
import { IntakeComponentEffects } from './+state/intake-component/intake.effects';
import { IntakeEffects } from './+state/intake.effects';
import { IntakeNextButtonComponent } from './intake/intake-next-button/intake-next-button.component';
import { leadsReducer, leadsSelectorKey } from './+state/reducers';
import { SuccessFormStepComponent } from './intake/steps/success-step/success-form-step.component';
import { SummarizeFormStepComponent } from './intake/steps/summarize-input/summarize-form-step.component';

@NgModule({
  imports: [
    DefaultComponentModule,
    DefaultFormComponentModule,
    StoreModule.forFeature(leadsSelectorKey, leadsReducer),
    EffectsModule.forFeature([
      IntakeEffects,
      BasicIntakeFormEffects,
      IntakeComponentEffects,
    ]),

    EffectsModule.forFeature([]),
    AddressCoreModule.forRoot({ api: apiPublicBasePath }),
    EplusEnergiewonenTwoSolarModule,
    EplusUiOnlyModule,
    EplusSolarPanelsModule,
  ],
  declarations: [
    BasicIntakeFormComponent,
    InputFormStepComponent,
    IntakeComponent,
    IntakeNextButtonComponent,
    SuccessFormStepComponent,
    SummarizeFormStepComponent,
  ],
  exports: [IntakeComponent],
})
export class EplusEnergiewonenIntakeModule {}
