import { validateSequential } from '@innogy/utils-deprecated';
import type { Action } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  onNgrxForms,
  updateGroup,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

export const PAYMENT_FORM_ID = 'paymentForm';
export const paymentIssuerControlId = `${PAYMENT_FORM_ID}.paymentIssuer`;
export interface PaymentFormValues {
  paymentIssuer: string;
}
export type PaymentFormState = FormGroupState<PaymentFormValues>;

export const initialFormState: PaymentFormValues = {
  paymentIssuer: '',
};
export const initialState = createFormGroupState<PaymentFormValues>(
  PAYMENT_FORM_ID,
  initialFormState
);

export const validateAndUpdateForms = (state: PaymentFormState) => {
  return updateGroup<PaymentFormValues>({
    paymentIssuer: validateSequential(required),
  })(state);
};

const _reducer = createReducer(initialState, onNgrxForms());
const wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state: PaymentFormState) => state,
  (_, state) => validateAndUpdateForms(state)
);

export function createPaymentFormReducer(
  state: PaymentFormState = initialState,
  action: Action
): PaymentFormState {
  return wrappedReducer(state, action);
}
