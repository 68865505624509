import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/jss-models';

@Component({
  selector: 'ep-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.energiewonen.scss'],
})
export class HeroComponent {
  @Input() rendering?: InnogyComponentRendering;
}
