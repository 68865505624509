import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { BaseService } from '@essent/common';
import { API_PREFIX } from '@innogy/core-config-angular';

import type {
  CreateTransactionPayload,
  CreateTransactionResponse,
} from './create-transaction.interface';

@Injectable()
export class CreateTransactionService
  implements BaseService<CreateTransactionPayload, CreateTransactionResponse>
{
  apiVersion = '';
  endpoint = `${this.apiPrefix}/energiewonen/ideal/create-transaction/`;

  constructor(
    private readonly http: HttpClient,
    @Inject(API_PREFIX) private readonly apiPrefix: string
  ) {}
  call$(body: CreateTransactionPayload) {
    return this.http.post<CreateTransactionResponse>(this.endpoint, body);
  }
}
