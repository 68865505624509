import { Injectable } from '@angular/core';
import {
  getAddressCheck,
  getAddressCheckClear,
  getAddressCheckError,
  getAddressCheckSuccess,
} from '@essent/address';
import { ofFormControlUnfocus } from '@innogy/utils-rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DisableAction, EnableAction, SetValueAction } from 'ngrx-forms';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';

import {
  disableStreetAndResidenceAction,
  resetBasicIntakeFormState,
} from './basic-intake-form.actions';
import {
  houseNumberAdditionControlId,
  houseNumberControlId,
  initialFormState,
  INTAKE_FORM_ID,
  postalCodeControlId,
  residenceControlId,
  streetControlId,
} from './basic-intake-form.reducer';
import { getBasicIntakeFormState } from './basic-intake-form.selectors';

@Injectable()
export class BasicIntakeFormEffects {
  resetStreetAndCityOnGet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAddressCheck),
      filter((action) => action.actionId === INTAKE_FORM_ID),
      map((_) => disableStreetAndResidenceAction())
    )
  );

  fetchAddressOnPostalCodeNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofFormControlUnfocus(
        [
          postalCodeControlId,
          houseNumberControlId,
          houseNumberAdditionControlId,
        ],
        this.store$,
        getBasicIntakeFormState
      ),
      distinctUntilChanged(
        (prev, curr) =>
          prev.value.postalCode === curr.value.postalCode &&
          prev.value.houseNumber === curr.value.houseNumber &&
          prev.value.houseNumberAddition === curr.value.houseNumberAddition
      ),
      filter((state) => !!state.value.postalCode),
      filter((state) => !!state.value.houseNumber),
      map((state) =>
        getAddressCheck({
          actionId: INTAKE_FORM_ID,
          payload: {
            houseNumber: `${state.value.houseNumber}${
              state.value.houseNumberAddition ?? ''
            }`,
            postcode: state.value.postalCode,
          },
        })
      )
    )
  );

  addressCheckSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAddressCheckSuccess),
      filter((action) => action.actionId === INTAKE_FORM_ID),
      mergeMap((action) => [
        new EnableAction(streetControlId),
        new SetValueAction(streetControlId, action.payload.street),
        new EnableAction(residenceControlId),
        new SetValueAction(residenceControlId, action.payload.city),
      ])
    )
  );

  addressCheckError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAddressCheckError),
      filter((action) => action.actionId === INTAKE_FORM_ID),
      mergeMap(() => [
        new EnableAction(streetControlId),
        new SetValueAction(streetControlId, initialFormState.street),
        new EnableAction(residenceControlId),
        new SetValueAction(residenceControlId, initialFormState.residence),
      ])
    )
  );

  disableStreetAndResidence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(disableStreetAndResidenceAction),
      mergeMap(() => [
        new DisableAction(streetControlId),
        new DisableAction(residenceControlId),
      ])
    )
  );

  resetIntakeFormState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetBasicIntakeFormState),
      map(() => getAddressCheckClear())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}
}
