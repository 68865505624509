import { NgModule } from '@angular/core';
import { combineEffectsWithProviders } from '@essent/common';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird';
import {
  DefaultComponentModule,
  DefaultFormComponentModule,
} from '@innogy/core/modules/components';
import { EplusUiOnlyModule } from '@innogy/eplus-partners/ui-only';
import { JssUtilsModule } from '@innogy/jss-utils';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PaymentContainerComponent } from './payment-container/payment-container.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { createTransactionApi } from './state/create-transaction/create-transaction.actions';
import { getIssuersApi } from './state/get-issuers/get-issuers.actions';
import { PaymentEffects } from './state/payment.effects';
import {
  paymentReducer,
  paymentReducerSelectorKey,
} from './state/payment.reducer';
import { updateStatusApi } from './state/update-status/update-status.actions';

const { EFFECTS, PROVIDERS } = combineEffectsWithProviders(
  createTransactionApi,
  getIssuersApi,
  updateStatusApi
);
@NgModule({
  providers: [...PROVIDERS, PaymentEffects],
  imports: [
    DefaultComponentModule,
    DefaultFormComponentModule,
    EplusUiOnlyModule,
    StoreModule.forFeature(paymentReducerSelectorKey, paymentReducer),
    EffectsModule.forFeature([...EFFECTS, PaymentEffects]),
    JssUtilsModule,
  ],
  declarations: [
    PaymentContainerComponent,
    PaymentFormComponent,
    PaymentStatusComponent,
  ],
  exports: [PaymentContainerComponent, PaymentStatusComponent],
})
export class EplusEnergiewonenPaymentModule {
  constructor(library: FaIconLibrary) {
    createLibrary(library);
  }
}

export const createLibrary = (library: FaIconLibrary) => {
  library.addIcons(faEdit, faSpinnerThird);
};
