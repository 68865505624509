import { Injectable, Injector } from '@angular/core';
import { BaseGuard } from '@innogy/core-ngrx';
import { Store } from '@ngrx/store';

import { getReviewInfo } from '../state/actions';
import { getReviewInfoIsLoading } from '../state/selectors';

@Injectable({ providedIn: 'root' })
export class ReviewInfoGuard extends BaseGuard {
  protected isStateLoading() {
    return this.store$.select(getReviewInfoIsLoading);
  }

  protected getDispatchActions() {
    return [getReviewInfo()];
  }

  constructor(
    protected readonly injector: Injector,
    public readonly store$: Store<any>
  ) {
    super(injector);
  }
}
