import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { JssRouteData } from '@innogy/jss-models';

import { ActiveLinkService } from '../navigation/active-link.service';

type UrlMatcher = 'contains' | 'exact';

@Pipe({
  name: 'isActiveLink',
})
export class IsActiveLinkPipe implements PipeTransform {
  constructor(private readonly activeLinkService: ActiveLinkService) {}

  transform(
    { href }: JssRouteData,
    urlMatcher: UrlMatcher = 'contains'
  ): boolean {
    return this.activeLinkService.isActiveLink(href, urlMatcher === 'exact');
  }
}
