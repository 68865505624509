import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import {
  required,
  greaterThanOrEqualTo,
  lessThanOrEqualTo,
  maxLength,
} from 'ngrx-forms/validation';
import {
  isEmailAddress,
  isNumeric,
  isPostalCode,
  validateSequential,
  isPhoneNumberAlt,
  isInitials,
  validateSequentialIf,
} from '@innogy/utils-deprecated';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  updateGroup,
  onNgrxForms,
  wrapReducerWithFormStateUpdate,
  validate,
} from 'ngrx-forms';
import { Gender } from '@essent/customer';

import { resetBasicIntakeFormState } from './basic-intake-form.actions';

export const INTAKE_FORM_ID = 'intakeForm';
export const streetControlId = `${INTAKE_FORM_ID}.street`;
export const residenceControlId = `${INTAKE_FORM_ID}.residence`;
export const postalCodeControlId = `${INTAKE_FORM_ID}.postalCode`;
export const houseNumberControlId = `${INTAKE_FORM_ID}.houseNumber`;
export const houseNumberAdditionControlId = `${INTAKE_FORM_ID}.houseNumberAddition`;

export interface IntakeFormValues {
  initials: string;
  insertion?: string;
  lastName: string;
  email: string;
  postalCode: string;
  houseNumber?: number;
  houseNumberAddition: string;
  phoneNumber: string;
  residence: string;
  street: string;
  gender: Gender;
  referralPostalCode: string;
  referralHouseNumber?: number;
}

export type IntakeFormState = FormGroupState<IntakeFormValues>;

export const initialFormState: IntakeFormValues = {
  initials: '',
  insertion: '',
  lastName: '',
  email: '',
  postalCode: '',
  houseNumber: undefined,
  houseNumberAddition: '',
  residence: '',
  street: '',
  phoneNumber: '',
  gender: Gender.MALE,
  referralPostalCode: '',
  referralHouseNumber: undefined,
};

export const initialState = createFormGroupState<IntakeFormValues>(
  INTAKE_FORM_ID,
  initialFormState
);

export const validateAndUpdateForms = (state: IntakeFormState) => {
  const hasReferrerFields =
    !!state.value.referralHouseNumber || !!state.value.referralPostalCode;

  return updateGroup<IntakeFormValues>({
    initials: validateSequential(required, isInitials),
    lastName: validateSequential(required),
    email: validateSequential(required, isEmailAddress),
    postalCode: validateSequential(required, isPostalCode),
    houseNumber: validateSequential(
      required,
      isNumeric,
      greaterThanOrEqualTo(1),
      lessThanOrEqualTo(99999)
    ),
    houseNumberAddition: validate(maxLength(8)),
    residence: validateSequential(required),
    street: validateSequential(required),
    phoneNumber: validateSequential(required, isPhoneNumberAlt),
    gender: validateSequential(required),
    referralPostalCode: validateSequentialIf(hasReferrerFields)(
      required,
      isPostalCode
    ),
    referralHouseNumber: validateSequentialIf(hasReferrerFields)(
      required,
      isNumeric,
      greaterThanOrEqualTo(1),
      lessThanOrEqualTo(99999)
    ),
  })(state);
};

const _reducer = createReducer(
  initialState,
  onNgrxForms(),
  on(resetBasicIntakeFormState, () => initialState)
);

const wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state: IntakeFormState) => state,
  (_, state) => validateAndUpdateForms(state)
);

export function intakeFormReducer(
  state: IntakeFormState = initialState,
  action: Action
): IntakeFormState {
  return wrappedReducer(state, action);
}
