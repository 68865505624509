import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { BaseService } from '@essent/common';
import { API_PREFIX } from '@innogy/core-config-angular';

import type {
  CreateReviewPayload,
  CreateReviewResponse,
} from '../../interfaces';

@Injectable()
export class CreateReviewService
  implements BaseService<CreateReviewPayload, CreateReviewResponse>
{
  readonly apiVersion = '';
  readonly endpoint = `${this.apiPrefix}/energiewonen/reviews/`;

  constructor(
    private readonly http: HttpClient,
    @Inject(API_PREFIX) private readonly apiPrefix: string
  ) {}
  call$(body: CreateReviewPayload) {
    return this.http.post<CreateReviewResponse>(this.endpoint, body);
  }
}
